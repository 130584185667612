import React from 'react';
import PropTypes from 'prop-types';
import ReferenceCard from './ReferenceCard';

const ReferenceList = ({ references }) => {
  return (
    <div className="pt-20-xxl pt-12">
      <div className="grid grid-cols-3 gap-16-xxl gap-12">
        {references.map((item, index) => {
          if (index % 7 === 0) {
            return (
              <div key={item.id} className="grid-span-3">
                <ReferenceCard {...item?.attributes} fullWidth />
              </div>
            );
          }
          return (
            <div key={item.id} className="grid-span-3 grid-span-1-lg">
              <ReferenceCard {...item?.attributes} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ReferenceList.propTypes = {
  references: PropTypes.array,
};

export default ReferenceList;
