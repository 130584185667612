import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

const ReferenceHeader = ({ currentSolution, solutions }) => {
  const { t } = useTranslation();
  const filterLabel = t('filterLabel', { returnObjects: true });
  return (
    <div className="flex flex-wrap items-center justify-center gap-5 transition-all">
      <Link
        className={`${
          currentSolution === null
            ? 'bg-blue-400 text-white border-blue-400'
            : 'bg-white text-blue-800 border-blue-400'
        } py-5-xxl py-4 px-8-xxl px-6 text-sm-xl text-xs font-medium border rounded-2`}
        to="/references-chantiers"
      >
        {filterLabel}
      </Link>
      {solutions?.map((solution) => (
        <Link
          key={solution.id}
          className={`${
            currentSolution === solution?.attributes?.slug
              ? 'bg-blue-400 text-white border-blue-400'
              : 'bg-white text-blue-800 border-blue-400'
          } py-5-xxl py-4 px-8-xxl px-6 text-sm-xl text-xs font-medium border rounded-2`}
          to={`/references-chantiers?solution=${solution?.attributes?.slug ?? ''}`}
        >
          {solution?.attributes?.name}
        </Link>
      ))}
    </div>
  );
};

ReferenceHeader.propTypes = {
  solutions: PropTypes.array,
  currentSolution: PropTypes.string,
};

export default ReferenceHeader;
