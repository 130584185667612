import { Link } from 'gatsby';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API_URL } from '../../api/axios';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const ReferenceCard = ({
  cover,
  icon,
  name,
  description,
  slug,
  fullWidth = false,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`flex flex-col items-center justify-${fullWidth ? 'center' : 'end'} w-full h-2xl-600-px h-400-px relative overflow-hidden py-12 transition-all`}
    >
      <button
        type="button"
        className="absolute top-0 left-0 h-full w-full opacity-0 z-10"
        onClick={() => setShow((prev) => !prev)}
      ></button>
      <img
        src={`${API_URL}${cover?.data?.attributes?.url}`}
        className="w-full h-full object-cover absolute top-0 left-0 -z-20"
        alt={name}
      />
      <div
        className={`absolute top-0 left-0 w-full h-full p-5 ${show ? 'bg-blue-400-87' : 'bg-blue-800-40'} -z-10`}
      >
        <div
          className={`w-full h-full border border-white ${!show ? 'opacity-0' : ''}`}
        />
      </div>
      <div
        className={`flex flex-col justify-${fullWidth ? 'center' : 'end'} items-center`}
      >
        <img
          src={`${API_URL}${icon?.data?.attributes?.url}`}
          height={80}
          width={80}
          className="object-fit"
          alt="Icon"
        />
        <span className="font-bold text-white text-2xl-xxl text-lg mt-4 text-center inline-block px-6">
          {name}
        </span>
      </div>
      <div
        className={`text-center max-w-320-px w-full pt-5 px-5 mx-auto ${show || fullWidth ? '' : 'hidden'}`}
      >
        <div
          className={`text-white text-base-xxl text-sm mb-4 text-center px-3 ${show || fullWidth ? '' : 'hidden'}`}
        >
          <BlocksRenderer content={description} />
        </div>
        <Link
          to={`/references-chantiers/${slug}`}
          className={`font-bold text-sm-xxl text-xs text-white text-center custom-underline relative z-10 ${show ? '' : 'hidden'}`}
          onClick={(e) => e.stopPropagation()}
        >
          Read more
        </Link>
      </div>
    </div>
  );
};

ReferenceCard.propTypes = {
  cover: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default ReferenceCard;
