import React from 'react';
import PropTypes from 'prop-types';
import ReferenceHeader from './ReferenceHeader';
import ReferenceList from './ReferenceList';

const ReferenceContainer = ({ references, solutions, currentSolution }) => {
  return (
    <div className="py-20-xxl py-12 container px-6 w-90-lg w-full mx-auto mb-20-xxl mb-10">
      <ReferenceHeader
        solutions={solutions}
        currentSolution={currentSolution}
      />
      <ReferenceList references={references} />
    </div>
  );
};

ReferenceContainer.propTypes = {
  references: PropTypes.array,
  solutions: PropTypes.array,
  currentSolution: PropTypes.string,
};

export default ReferenceContainer;
