import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SharedHero from '../../../shared/SharedHero';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const title = t('title', { returnObjects: true });
  return (
    <SharedHero title={title}>
      <StaticImage
        src="../../../images/history/history-hero.jpg"
        quality={100}
        layout="constrained"
        className="absolute top-0 left-0 h-full w-full object-cover -z-10"
        alt="History hero"
      />
      <div className="absolute top-0 left-0 h-full w-full object-cover -z-10 gradient-blue-62" />
    </SharedHero>
  );
};

export default Hero;
