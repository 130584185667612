import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../../shared/PageLayout';
import Hero from '../../page-components/References/Hero';
import ReferenceContainer from '../../page-components/References/ReferencesContainer';
import CTASection from '../../page-components/homePage/CTA';
import axios from '../../api/axios';
import { graphql } from 'gatsby';

const References = ({ serverData }) => {
  const { references, solutions, headerSolutions, currentSolution } =
    serverData;
  return (
    <PageLayout solutions={headerSolutions}>
      <Hero />
      <ReferenceContainer
        currentSolution={currentSolution}
        solutions={solutions}
        references={references}
      />
      <CTASection />
    </PageLayout>
  );
};

References.propTypes = {
  serverData: PropTypes.object,
};

export default References;

export async function getServerData({ query }) {
  const currentSolution = query.solution;
  try {
    const { data: solutionsData } = await axios.get('/api/solutions', {
      params: {
        fields: ['name', 'slug'],
      },
    });

    let filters = {};
    if (currentSolution) {
      filters = {
        solution: {
          slug: { $eq: currentSolution },
        },
      };
    }
    const { data } = await axios.get('/api/references', {
      params: {
        filters: filters,
        populate: {
          cover: {
            fields: ['url'],
          },
          icon: {
            fields: ['url'],
          },
        },
        fields: ['name', 'slug', 'description'],
      },
    });

    const { data: headerSolutionsData } = await axios.get('/api/solutions', {
      params: {
        populate: {
          products: {
            populate: {
              product_types: {
                populate: {
                  product_items: { populate: true },
                },
              },
            },
          },
        },
        filters: {
          slug: { $eq: currentSolution },
        },
      },
    });
    return {
      props: {
        references: data?.data,
        solutions: solutionsData?.data,
        currentSolution: currentSolution ?? null,
        headerSolutions: headerSolutionsData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes?.products?.data?.map((subItem) => ({
            title: subItem?.attributes?.name,
            url: subItem?.attributes?.slug,
            subMenu: subItem?.attributes?.product_types?.data?.map(
              (subSubItem) => ({
                title: subSubItem?.attributes?.name,
                url: subSubItem?.attributes?.slug,
              }),
            ),
          })),
        })),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["References", "Layout"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
